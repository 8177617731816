import React, { useState, useEffect } from 'react';

const PasswordGenerator = () => {
  const [password, setPassword] = useState('');
  const [strength, setStrength] = useState('');
  const [crackTime, setCrackTime] = useState('');
  const [charCount, setCharCount] = useState(14);
  const [type, setType] = useState('password');
  const [copyFeedback, setCopyFeedback] = useState(false);
  const [options, setOptions] = useState({
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: false,
    capitalize: false,
    includeNumber: false,
  });
  const [wordSeparator, setWordSeparator] = useState('-');

  const generatePassword = () => {
    if (type === 'passphrase') {
      const words = ['apple', 'banana', 'cherry', 'date', 'elderberry', 'fig', 'grape', 'honeydew', 'kiwi', 'lemon', 'mango', 'nectarine', 'orange', 'papaya', 'quince', 'raspberry', 'strawberry', 'tangerine', 'ugli', 'vanilla', 'watermelon', 'xigua', 'yuzu', 'zucchini'];
      let passphrase = Array(4).fill().map(() => words[Math.floor(Math.random() * words.length)]);

      if (options.capitalize) {
        passphrase = passphrase.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      }

      if (options.includeNumber) {
        const randomIndex = Math.floor(Math.random() * 4);
        passphrase[randomIndex] += Math.floor(Math.random() * 10);
      }

      setPassword(passphrase.join(wordSeparator));
    } else {
      let chars = '';
      if (options.uppercase) chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (options.lowercase) chars += 'abcdefghijklmnopqrstuvwxyz';
      if (options.numbers) chars += '0123456789';
      if (options.symbols) chars += '!@#$%^&*()_+-=[]{}|;:,.<>?';

      let newPassword = '';
      for (let i = 0; i < charCount; i++) {
        newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      setPassword(newPassword);
    }
  };

  useEffect(() => {
    generatePassword();
  }, [charCount, options, type, wordSeparator]);

  useEffect(() => {
    const calculateStrength = () => {
      let score = 0;
      if (password.length >= 12) score++;
      if (password.match(/[A-Z]/)) score++;
      if (password.match(/[a-z]/)) score++;
      if (password.match(/[0-9]/)) score++;
      if (password.match(/[^A-Za-z0-9]/)) score++;

      if (score <= 2) return 'weak';
      if (score <= 4) return 'medium';
      return 'strong';
    };

    const strength = calculateStrength();
    setStrength(strength);

    const crackTimes = {
      weak: 'seconds',
      medium: 'days',
      strong: 'centuries',
    };
    setCrackTime(crackTimes[strength]);
  }, [password]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password);
    setCopyFeedback(true);
    setTimeout(() => setCopyFeedback(false), 2000);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mt-8 mb-2 text-center text-gray-800">Free and Secure Password Generator</h1>
      <h2 className="text-center text-lg text-gray-600 mb-8">Use our tool below to generate a unique and secure password</h2>
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full mb-6">
        <div className="mb-6 flex justify-between text-sm">
          <span>Score: <span className="font-semibold text-blue-600">{strength}</span></span>
          <span>Estimated crack time: <span className="font-semibold text-blue-600">{crackTime}</span></span>
        </div>

        <div className="bg-gray-100 p-4 rounded-md mb-6">
          <p className="text-2xl text-center font-mono break-all">{password}</p>
        </div>

        <div className="flex mb-6 gap-4">
          <button
            onClick={copyToClipboard}
            className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors duration-300 ease-in-out flex items-center justify-center relative"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
              <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
            </svg>
            {copyFeedback ? 'Copied!' : 'Copy'}
            {copyFeedback && (
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded">
                Copied to clipboard!
              </span>
            )}
          </button>
          <button
            onClick={generatePassword}
            className="flex-1 bg-green-600 text-white py-3 px-4 rounded-md hover:bg-green-700 transition-colors duration-300 ease-in-out flex items-center justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
            </svg>
            Regenerate
          </button>
        </div>

        <div className="mb-6">
          <div className="flex items-center mb-4">
            <span className="mr-4 text-gray-700">Type:</span>
            <label className="mr-4 flex items-center">
              <input
                type="radio"
                value="password"
                checked={type === 'password'}
                onChange={() => setType('password')}
                className="mr-2"
              />
              <span className="text-gray-700">Password</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="passphrase"
                checked={type === 'passphrase'}
                onChange={() => setType('passphrase')}
                className="mr-2"
              />
              <span className="text-gray-700">Passphrase</span>
            </label>
          </div>
          <div className="flex flex-row items-center">
            <span className="inline-flex items-center whitespace-nowrap text-gray-700 mr-6">Length: {charCount}</span>
            <input
              type="range"
              min="8"
              max="32"
              value={charCount}
              onChange={(e) => setCharCount(parseInt(e.target.value))}
              className="w-full"
            />
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-3 text-gray-800">Additional Options</h3>
          <div className="grid grid-cols-2 gap-3 mb-4">
            {['uppercase', 'lowercase', 'numbers', 'symbols'].map((key) => (
              <label key={key} className="flex items-center">
                <input
                  type="checkbox"
                  checked={options[key]}
                  onChange={() => setOptions(prev => ({ ...prev, [key]: !prev[key] }))}
                  className="mr-2"
                  disabled={type === 'passphrase'}
                />
                <span className="text-gray-700 capitalize">{key}</span>
              </label>
            ))}
          </div>
          <div className="grid grid-cols-2 gap-3">
            {['capitalize', 'includeNumber'].map((key) => (
              <label key={key} className="flex items-center">
                <input
                  type="checkbox"
                  checked={options[key]}
                  onChange={() => setOptions(prev => ({ ...prev, [key]: !prev[key] }))}
                  className="mr-2"
                  disabled={type === 'password'}
                />
                <span className="text-gray-700">{key === 'capitalize' ? 'Capitalize' : 'Include Number'}</span>
              </label>
            ))}
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-gray-700 mr-4">Word separator:</span>
            <input
              type="text"
              value={wordSeparator}
              onChange={(e) => setWordSeparator(e.target.value)}
              className="border rounded px-2 py-1 w-16 text-center"
              disabled={type === 'password'}
            />
          </div>
        </div>
      </div>
      <section className="py-12 px-4">
        <div className="container max-w-4xl mx-auto text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Why a Strong Password is Important</h2>
          <p className="text-lg text-gray-600 mb-8">
            A strong password is crucial for protecting your personal and financial information.
            Weak passwords can be easily guessed or cracked by criminals, leading to unauthorized access to your accounts.
          </p>
          <div className="flex flex-col md:flex-row md:justify-center gap-6">
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2">Protect Your Data</h3>
              <p className="text-gray-600">
                Strong passwords help keep your sensitive data secure.
              </p>
            </div>
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2">Prevent Unauthorized Access</h3>
              <p className="text-gray-600">
                A complex password makes it more difficult for attackers to gain access to your accounts and information.
              </p>
            </div>
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2">Enhance Security</h3>
              <p className="text-gray-600">
                By using unique and strong passwords, you significantly improve your overall online security.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 px-4">
        <div className="container max-w-4xl mx-auto text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">What Makes for a Strong Password</h2>
          <p className="text-lg text-gray-600 mb-8">
            A strong password typically combines various elements to create a complex and unpredictable string that is resistant to attacks and difficult to guess.
          </p>
          <div className="flex flex-col md:flex-row md:justify-center gap-6">
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2">Length</h3>
              <p className="text-gray-600">
                The longer the password, the harder it is to crack. A strong password should ideally be at least 12 characters long.
              </p>
            </div>
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2">Complexity</h3>
              <p className="text-gray-600">
                Incorporate a mix of uppercase and lowercase letters, numbers, and special symbols (e.g., !, @, #, $).
              </p>
            </div>
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2">Uniqueness</h3>
              <p className="text-gray-600">
                Use a unique password for each of your accounts. This prevents a breach in one account from compromising your other accounts.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PasswordGenerator;
